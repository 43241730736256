
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import { formatDateTime } from '@/utils/helpers'
import { DELIVER_LOG } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { IDeliverLog } from '@/utils/types'
import { CAMPAIGN_TYPE } from '@/utils/constants'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: { formatDateTime, CLoading, TestModeSelector },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class DeliveredLogModal extends Vue {
  @Prop()
  pushId!: string

  @Prop()
  modalVisible!: boolean

  isTestMode = false

  formatDateTime = formatDateTime

  loading = true

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  deliver_logs: IDeliverLog[] = []

  checkDiffirent(to: string, from: string) {
    return to !== from ? 1 : 0
  }

  audienceFailed(row: IDeliverLog) {
    // https://developers.line.biz/en/reference/messaging-api/#status-codes
    // 429 Exceeded the number of free messages.
    if (row.error_code === 429) {
      const msg = this.$t('messages.failed_recipients_due_quota', {
        audienceFailed: row.failure_count,
      })
      return msg
    } else {
      return row.failed_description
    }
  }

  @Watch('$store.getters.deliver_logs')
  deliverLogs() {
    this.deliver_logs = cloneDeep(this.$store.getters.deliver_logs)
  }

  @Watch('isTestMode', { immediate: true })
  @Watch('pushId', { immediate: true })
  async appIdChanged() {
    if (!this.$route.params.app_id) {
      return
    }

    await this.$store.dispatch(DELIVER_LOG.SET_ITEMS, {
      app_id: this.$route.params.app_id,
      campaign_id: this.pushId,
      campaign_type: CAMPAIGN_TYPE.PUSH,
      is_test_mode: this.isTestMode,
    })

    this.loading = false
  }

  onCancel() {
    this.visible = false
  }
}
